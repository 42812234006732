import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealEncouragement', 'textStepperEncouragement', 'textRevealAcknowledgement', 'textStepperAcknowledgement', 'textRevealAffection', 'textStepperAffection', 'textRevealAppreciation', 'textStepperAppreciation'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const TextStepper = makeShortcode("TextStepper");
const Quote = makeShortcode("Quote");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "things-you-can-say"
    }}>{`Things you can say`}</h1>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealEncouragement" header="Encouragement/Praise" mountOnEnter={true} mdxType="TextReveal">
      <TextStepper id="textStepperEncouragement" sequenceSpeed={1200} fadeSpeed={1200} elevation={0} list={[{
            text: 'You worked hard; you deserve it.'
          }, {
            text: 'You should be proud of yourself!'
          }, {
            text: 'Look at your improvement!'
          }, {
            text: "That 'A' reflects a lot of hard work!"
          }, {
            text: 'You worked really hard to get this room clean!'
          }, {
            text: 'Your hard work paid off!'
          }, {
            text: "That's a tough one, but you'll figure it out."
          }, {
            text: "Look how far you've come!"
          }, {
            text: "That's coming along nicely!"
          }, {
            text: 'You really worked it out!'
          }, {
            text: 'I see a very thorough job!'
          }, {
            text: 'You make it look easy!'
          }, {
            text: "You've really got the hang of it!"
          }, {
            text: 'You did it!'
          }]} mdxType="TextStepper" />
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealAcknowledgement" header="Acknowledgement" mountOnEnter={true} mdxType="TextReveal">
      <TextStepper id="textStepperAcknowledgement" sequenceSpeed={1200} fadeSpeed={1200} elevation={0} list={[{
            text: "I'm listening…"
          }, {
            text: "That's a very good observation."
          }, {
            text: 'I can tell you really care.'
          }, {
            text: 'I can tell you spent a lot of time thinking this through.'
          }, {
            text: 'You seem to really enjoy maths.'
          }, {
            text: 'I noticed you were really patient with your little sister.'
          }, {
            text: "The time you're putting into your homework is really paying off."
          }, {
            text: 'What do you think about it?'
          }]} mdxType="TextStepper" />
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealAffection" header="Affection" mountOnEnter={true} mdxType="TextReveal">
      <TextStepper id="textStepperAffection" sequenceSpeed={1200} fadeSpeed={1200} elevation={0} list={[{
            text: 'I love you.'
          }, {
            text: 'I love being with you.'
          }]} mdxType="TextStepper" />
    </TextReveal>
  </Grid>
  <Grid item xs={12} sm={6} mdxType="Grid">
    <TextReveal id="textRevealAppreciation" header="Appreciation" mountOnEnter={true} mdxType="TextReveal">
      <TextStepper id="textStepperAppreciation" sequenceSpeed={1200} fadeSpeed={1200} elevation={0} list={[{
            text: 'You really put a smile on her face with your kind words!'
          }, {
            text: 'Thanks for setting the table, that made a big difference.'
          }, {
            text: 'Thank you for helping.'
          }, {
            text: 'I really feel like a team when we work like this!'
          }]} mdxType="TextStepper" />
    </TextReveal>
  </Grid>
    </Grid>
    <Quote quote="“A child needs encouragement like a plant needs water.”" author="Rudolph Dreikurs" mdxType="Quote" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      